import { AnimatedGroup, SectionHeading } from '../../../../components';
import { Col, Grid, Section } from '../../../../theme/components';
import { PlainText } from 'prismic-react-tools';
import { Text } from '../../../../theme/components/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const pad = value => (value + 1 < 10 ? `0${(value + 1).toString()}` : (value + 1).toString());

export const Process = props => {
  const { items, ...heading } = props;

  return (
    <Section>
      <SectionHeading {...heading} />
      <Grid>
        <AnimatedGroup center="xs" stagger={0.1}>
          {items.map(({ heading, icon: { url: icon }, text }, index) => (
            <Col
              key={index}
              left
              md={3}
              mdOffset={index % 3 === 0 ? 0 : 1}
              mt={{ md: index > 2 ? 3.5 : 0, xs: 2 }}
              textAlign={{ md: 'left', xs: 'center' }}
              xs={12}
            >
              <img src={icon} />
              <Text centeredOnTablet mt={1} semibold>
                <Text as="span" mr={0.25} p06 semibold>
                  {pad(index)}
                </Text>
                <PlainText content={heading} />
              </Text>
              <Text as="div" centeredOnTablet medium mt={1}>
                <PlainText content={text} />
              </Text>
            </Col>
          ))}
        </AnimatedGroup>
      </Grid>
    </Section>
  );
};

Process.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string
      }),
      text: PropTypes.any
    })
  ),
  preHeading: PropTypes.string,
  text: PropTypes.any
};

import { Facilities, MVP, Process, Specialized } from './components';
import { Hero } from '../../components';
import { extractFromCamelCase } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';
import withPrismicPage from '../../HOC/withPrismicPage';

const Services = props => {
  const {
    pageContext: { document }
  } = props;

  const { mostValuePropositions } = document;
  const hero = extractFromCamelCase(document, 'hero');
  const process = extractFromCamelCase(document, 'process');
  const specialized = extractFromCamelCase(document, 'specialized');
  const facilities = extractFromCamelCase(document, 'facilities');

  return (
    <>
      <Hero {...hero} backgroundImage="/img/hero/services-hero-bkg.jpg" />
      <MVP mvp={mostValuePropositions} />
      <Process {...process} />
      <Specialized {...specialized} />
      <Facilities {...facilities} />
    </>
  );
};

Services.propTypes = {
  pageContext: PropTypes.object
};

export default withPrismicPage(Services, { withTestimonials: true });

import { Text } from '../../../../theme/components/Typography';
import { colors } from '../../../../theme';
import { size } from 'polished';
import styled from 'styled-components';

const SpecializedServices = styled.ul``;

const SpecializedServiceHeading = styled(Text).attrs({
  as: 'h4',
  p06: true,
  semibold: true
})``;

const SpecializedService = styled.li`
  margin-top: 1.25rem;
`;

const SpecializedServiceItems = styled.ul``;

const SpecializedServiceItem = styled.li`
  & + & {
    margin-top: 1.25rem;
  }
`;

const SpecializedServiceItemHeading = styled(Text).attrs({
  as: 'h6',
  medium: true,
  n07: true,
  semibold: true
})`
  display: flex;
  margin-top: 1.25rem;

  svg {
    ${size('2rem')};

    fill: ${colors.n035};
  }
`;

const SpecializedServiceItemContent = styled(Text).attrs({
  as: 'div',
  medium: true
})`
  padding-left: 2rem;
  margin-top: 0.5rem;

  & > p + p {
    margin-top: 1rem;
  }

  ul {
    list-style: disc;
    padding-left: 1rem;

    & > li + li {
      margin-top: 0.5rem;
    }
  }
`;

export {
  SpecializedService,
  SpecializedServiceHeading,
  SpecializedServiceItem,
  SpecializedServiceItemContent,
  SpecializedServiceItemHeading,
  SpecializedServiceItems,
  SpecializedServices
};

import { AnimatedEl, AnimatedGroup, SectionContent, SectionImage } from '../../../../components';
import { Grid, Icon, Section } from '../../../../theme/components';
import { RichText } from 'prismic-react-tools';
import {
  SpecializedService,
  SpecializedServiceHeading,
  SpecializedServiceItem,
  SpecializedServiceItemContent,
  SpecializedServiceItemHeading,
  SpecializedServiceItems,
  SpecializedServices
} from './Specialized.style';
import PropTypes from 'prop-types';
import React from 'react';

export const Specialized = props => {
  const {
    image: { url: image },
    items,
    ...content
  } = props;

  return (
    <Section mediumPadding mt={4}>
      <Grid>
        <AnimatedGroup reverse>
          <AnimatedEl animation={{ from: 'right' }} mb={{ md: 0, xs: 4 }} md={6} mdOffset={1} xs={12}>
            <SectionImage side="right" splat="mainSplat" src={image} />
          </AnimatedEl>
          <AnimatedEl md={5} xs={12}>
            <SectionContent {...content}>
              <SpecializedServices>
                {items.map(({ items: specializedItems, primary: { heading } }, index) => (
                  <SpecializedService key={index}>
                    <SpecializedServiceHeading>{heading}</SpecializedServiceHeading>
                    <SpecializedServiceItems>
                      {specializedItems.map(({ itemContent, itemHeading }, specializedItemIndex) => (
                        <SpecializedServiceItem key={`${index}-${specializedItemIndex}`}>
                          <SpecializedServiceItemHeading>
                            <Icon icon="arrowRight" />
                            {itemHeading}
                          </SpecializedServiceItemHeading>
                          <SpecializedServiceItemContent>
                            <RichText content={itemContent} />
                          </SpecializedServiceItemContent>
                        </SpecializedServiceItem>
                      ))}
                    </SpecializedServiceItems>
                  </SpecializedService>
                ))}
              </SpecializedServices>
            </SectionContent>
          </AnimatedEl>
        </AnimatedGroup>
      </Grid>
    </Section>
  );
};

Specialized.propTypes = {
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          itemContent: PropTypes.any,
          itemHeading: PropTypes.string
        })
      ),
      primary: PropTypes.shape({
        heading: PropTypes.string
      })
    })
  ),
  preHeading: PropTypes.string,
  text: PropTypes.any
};

import { AnimatedEl, AnimatedGroup, SectionContent, SectionImage } from '../../../../components';
import { Grid, Section } from '../../../../theme/components';
import PropTypes from 'prop-types';
import React from 'react';

export const MVP = props => {
  const { mvp } = props;

  return (
    <Section smallPadding>
      <Grid>
        {mvp.map(({ image: { url: image }, ...content }, index) => (
          <AnimatedGroup
            key={index}
            middle="xs"
            mt={{ md: index !== 0 ? 12 : 0, xs: index !== 0 ? 6 : 0 }}
            reverse={index % 2 !== 0}
          >
            <AnimatedEl
              mb={{ md: 0, xs: 4 }}
              md={7}
              mdOffset={index % 2 !== 0 ? 1 : null}
              textAlign={{ md: 'left', xs: 'center' }}
              xs={12}
            >
              <SectionImage side={index % 2 !== 0 ? 'right' : 'left'} splat={`splat${index + 1}`} src={image} />
            </AnimatedEl>
            <AnimatedEl
              animation={{ from: index % 2 === 0 ? 'right' : 'left' }}
              md={4}
              mdOffset={index % 2 === 0 ? 1 : null}
              textAlign={{ md: 'left', xs: 'center' }}
              xs={12}
            >
              <SectionContent {...content} buttonProps={{ large: true }} />
            </AnimatedEl>
          </AnimatedGroup>
        ))}
      </Grid>
    </Section>
  );
};

MVP.propTypes = {
  mvp: PropTypes.arrayOf(
    PropTypes.shape({
      buttonLabel: PropTypes.string,
      buttonUrl: PropTypes.string,
      heading: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string
      }),
      text: PropTypes.array
    })
  )
};
